.connected-button {
  width: 100%;
  height: 56px;
  border: 1px solid transparent;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.connected-button.is-connected {
  justify-content: flex-start;
  cursor: default;
  color: var(--light-color);
}

.connected-button .anticon {
  font-size: 18px;
}

.connected-button__close-icon {
  margin-left: auto;
  cursor: pointer;
}

.connected-button__connected-content {
  margin-left: 12px;
  font-size: 12px;
}

.connected-button__username {
  font-size: 16px;
  font-weight: 600;
  color: var(--main-color);
}

.connected-button__disconnected-content {
  margin-left: 10px;
  font-weight: 600;
  font-size: 16px;
}

@primary-color: #EA4D89;@text-color: #1d2c3b;@text-muted: #8C9DB2;@text-color-secondary: #8C9DB2;@font-size-lg: 14px;@card-head-font-size: 14px;@border-radius-base: 4px;