.headerControlPanel {
  display: flex;
  flex-direction: row;
  height: 72px;
  width: 210px;
  @media (max-width: 900px) {
    display: none;
  }
}

.controller {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 73px;
  width: 36px;
  font-size: 21px;
  color: var(--unnamed-color-b0b0b0);
  margin: 0 3px;
  cursor: pointer;
}

.controller:hover {
  color: var(--main-color);
}

.selectedController {
  color: var(--main-color);
  border-bottom: 2px solid var(--main-color);
}

.headerControlPanelMenuButton {
  display: none;
  height: 72px;
  width: 36px;
  font-size: 20px;
  color: var(--unnamed-color-b0b0b0);

  @media (max-width: 900px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.headerControlPanelMenuItem {
  font-size: 13px;
  color: var(--main-color);
}

.headerControlPanelMenuItem .anticon {
  font-size: 19px !important;
  color: var(--unnamed-color-b0b0b0);
}

.headerControlPanelMenuItem .ant-avatar {
  font-size: 17px !important;
  width: 22px;
  height: 22px;
  line-height: 22px;
  margin-right: 4px;
  .anticon {
    color: white;
  }
}

.headerControlPanelSelectedMenuItem {
  color: #fff;
  background: @primary-color;
  opacity: 0.4;
}

.headerControlPanelSelectedMenuItem .anticon {
  color: #fff;
}

@primary-color: #EA4D89;@text-color: #1d2c3b;@text-muted: #8C9DB2;@text-color-secondary: #8C9DB2;@font-size-lg: 14px;@card-head-font-size: 14px;@border-radius-base: 4px;