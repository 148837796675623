.headerControlSearch {
  padding: 0 40px;
  width: calc(100vw - 510px);
  height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (max-width: 900px) {
    width: calc(100vw - 340px);
  }
}

.headerControlSearch .ant-input-affix-wrapper {
  height: 38px !important;
  max-width: 700px;
  min-width: 350px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--unnamed-color-f3f5f7);
  border: none;
  border-radius: 4px;
}

.headerControlSearch .ant-input {
  height: 30px !important;
  background: var(--unnamed-color-f3f5f7);
  color: #162745;
  font-size: 13px;
  letter-spacing: 0;
  opacity: 1;
}

.headerControlSearch .ant-input-search-icon::before {
  border-left: none;
}

@primary-color: #EA4D89;@text-color: #1d2c3b;@text-muted: #8C9DB2;@text-color-secondary: #8C9DB2;@font-size-lg: 14px;@card-head-font-size: 14px;@border-radius-base: 4px;