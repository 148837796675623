.sidebar .ant-drawer-header {
  padding: 40px 40px 45px 45px;
}

.sidebar .ant-drawer-close {
  font-size: var(--unnamed-font-size-20);
}

.sidebar .ant-drawer-body {
  padding-left: 30px;
  padding-top: 30px;
}

.sidebar button.ant-drawer-close {
  padding-top: 40px;
  padding-right: 40px;
}

.menu {
  border: none;
  font-size: 18px;
}

.menuItem {
  display: flex;
  align-items: center;
  margin-bottom: 25px !important;
}

.menuItem .anticon {
  font-size: var(--unnamed-font-size-20);
  color: @primary-color;
  margin-right: 20px;
}

.menuDivider {
  margin-bottom: 25px !important;
}

.avatar {
  border-radius: 8px;
  margin-bottom: 20px;
}

.fullName {
  font-size: var(--unnamed-font-size-20);
  color: var(--main-color);
  margin-bottom: 5px;
  font-weight: 600;
}

.username {
  font-size: 16px;
  color: @primary-color;
  margin-bottom: 10px;
}

.info {
  color: var(--light-color);
  font-size: 16px;
  display: flex;
}

.info > div {
  cursor: pointer;
}

.info > :not(:last-child) {
  margin-right: 26px;
}

.info span {
  font-weight: 600;
}

@primary-color: #EA4D89;@text-color: #1d2c3b;@text-muted: #8C9DB2;@text-color-secondary: #8C9DB2;@font-size-lg: 14px;@card-head-font-size: 14px;@border-radius-base: 4px;