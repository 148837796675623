.settings__profile {
  margin-top: 15px;
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
  overflow: hidden;
}

.avatar-uploader > .ant-upload:hover .camera {
  opacity: 1;
}

.settings__profile__row {
  display: flex;
  width: 100%;
  max-width: 720px;
}

.settings__profile__row .ant-form-item {
  width: calc(50% - 10px);
}

.settings__profile__row .ant-form-item:not(:last-child) {
  margin-right: 20px;
}

.settings__profile__form {
  margin-top: 24px;
  margin-bottom: 24px;
}

.settings__profile__form .ant-form-item {
  max-width: 720px;
  min-width: 175px;
}

.settings__profile__button {
  margin-left: 50%;
  transform: translateX(-50%);
  width: 350px;
  height: 56px;
}

.settings__profile__button:not(:first-child) {
  margin-top: 20px;
}

@primary-color: #EA4D89;@text-color: #1d2c3b;@text-muted: #8C9DB2;@text-color-secondary: #8C9DB2;@font-size-lg: 14px;@card-head-font-size: 14px;@border-radius-base: 4px;