.settings__account__section {
  color: var(--main-color);
  font-size: 26px;
  text-transform: capitalize;
  font-weight: bold;
  margin-bottom: 10px;
}

.settings__account__form {
  margin-top: 25px;
  margin-bottom: 25px;
}

.settings__account__row {
  display: flex;
  width: 100%;
  max-width: 720px;
}

.settings__account__row .ant-form-item {
  width: calc(50% - 10px);
}

.settings__account__row .ant-form-item:not(:last-child) {
  margin-right: 20px;
}

.settings__account__row.triple {
  max-width: 1090px;
}

.settings__account__row.triple .ant-form-item {
  width: calc(33% - 10px);
}

.settings__account__button {
  margin-left: 50%;
  transform: translateX(-50%);
  width: 350px;
  height: 56px;
}

.settings__account__button:not(:first-child) {
  margin-top: 20px;
}

.settings__account__deactivate {
  font-size: 16px;
  font-weight: 600;
}

.settings__account__deactivate-link {
  text-decoration: underline;
  cursor: pointer;
}

@primary-color: #EA4D89;@text-color: #1d2c3b;@text-muted: #8C9DB2;@text-color-secondary: #8C9DB2;@font-size-lg: 14px;@card-head-font-size: 14px;@border-radius-base: 4px;