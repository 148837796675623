.page {
  width: 100%;
  height: 100vh;
  background-color: white;
  padding: 102px 45px 0;
}

.pageNoScroll {
  overflow: hidden;
}

.pageNoHeader {
  padding-top: 0;
}

@primary-color: #EA4D89;@text-color: #1d2c3b;@text-muted: #8C9DB2;@text-color-secondary: #8C9DB2;@font-size-lg: 14px;@card-head-font-size: 14px;@border-radius-base: 4px;