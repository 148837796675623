.add-post-card {
  border: 1px solid var(--outline);
  border-bottom: none;
  border-radius: 8px;
  height: 148px;

  .add-post-card__Footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    height: 60px;
    background: var(--unnamed-color-f3f5f7) 0% 0% no-repeat padding-box;
    border: 1px solid var(--outline);
    background: #f3f5f7 0% 0% no-repeat padding-box;
    border: 1px solid #e5e9ec;
    border-radius: 8px;
    opacity: 1;
    button,
    .ant-input-affix-wrapper {
      width: initial;
      color: @text-muted;
      border: none;
      margin: 0 5px;
    }
    .ant-btn-primary {
      width: 136px;
      color: white;
    }
    button {
      height: 40px;
    }
    input {
      height: 30px !important;
    }
  }
  margin-bottom: 30px;
  .add-post-input {
    height: 88px;
  }
}

@primary-color: #EA4D89;@text-color: #1d2c3b;@text-muted: #8C9DB2;@text-color-secondary: #8C9DB2;@font-size-lg: 14px;@card-head-font-size: 14px;@border-radius-base: 4px;