.comingSoon {
  position: relative;
  padding-top: 43px;
  height: 100%;
  z-index: 100;
}

.comingSoon form {
  z-index: 100;
}

.comingSoonInputContainer {
  display: flex;
  justify-content: center;
  margin-top: 36px;
}

.comingSoonInputContainer input {
  width: 330px;
  height: 56px;
  margin-right: 20px;
}

.comingSoonInputContainer button {
  width: 135px;
  height: 56px;
  font-size: 16px;
}

.comingSoonTriangleContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.comingSoonTriangle {
  width: 0;
  height: 0;
  border-bottom: 240px solid #ffe0f9;
  border-right: 1542px solid transparent;
}

.comingSoonMobiles {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  margin-top: 74px;
  overflow: hidden;
}

.comingSoonMobiles img {
  width: 600px;
  height: 654px;
  object-fit: contain;
}

@media screen and (max-height: 721px) {
  .comingSoonMobiles {
    bottom: -390px;
  }
}

@media screen and (max-height: 650px) {
  .comingSoonMobiles {
    bottom: auto;
  }
}

@media screen and (max-width: 700px) {
  .comingSoonMobiles {
    position: relative;
    bottom: auto;
  }

  .comingSoonMobiles img {
    width: 500px;
    height: 520px;
  }
}

@media screen and (max-width: 530px) {
  .comingSoonMobiles img {
    width: 400px;
    height: 410px;
  }
}

@media screen and (max-width: 450px) {
  .comingSoonMobiles img {
    width: 300px;
    height: 305px;
  }

  .comingSoonInputContainer {
    flex-direction: column;
    align-items: center;
  }

  .comingSoonInputContainer input {
    margin-right: auto;
  }
}

@primary-color: #EA4D89;@text-color: #1d2c3b;@text-muted: #8C9DB2;@text-color-secondary: #8C9DB2;@font-size-lg: 14px;@card-head-font-size: 14px;@border-radius-base: 4px;