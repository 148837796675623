.label {
  position: absolute;
  color: rgba(22, 39, 69, 0.4);
  font-size: 13px;
  line-height: 11px;
  letter-spacing: 0;
  top: 17px;
  margin-left: 10px;
  padding: 0 2px 0 2px;
  z-index: 1;
  border-radius: 3px;
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

.hasValue .label {
  font-size: 12px;
  top: 6px;
}

.hasValue .ant-input-lg {
  padding: 10.5px 11px 0px;
  transition: all 0.2s ease-in-out;
}

.hasAddonBefore .label {
  margin-left: 45px;
}

.hasAddonBefore .ant-input {
  z-index: 0 !important;
}

.ant-input {
  height: 44px !important;
  color: #162745;
  font-size: 13px;
  letter-spacing: 0;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  opacity: 1;
}

@primary-color: #EA4D89;@text-color: #1d2c3b;@text-muted: #8C9DB2;@text-color-secondary: #8C9DB2;@font-size-lg: 14px;@card-head-font-size: 14px;@border-radius-base: 4px;