.title {
  color: @text-color;
  font-weight: bold;
  font-size: var(--unnamed-font-size-44);
  text-transform: capitalize;
  margin-top: 5px;
  z-index: 100;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

@primary-color: #EA4D89;@text-color: #1d2c3b;@text-muted: #8C9DB2;@text-color-secondary: #8C9DB2;@font-size-lg: 14px;@card-head-font-size: 14px;@border-radius-base: 4px;