.card-description {
  color: @text-muted;
}
.post-card {
  .ant-card-meta-title {
    margin-bottom: 0 !important;
  }
}

.feed-item {
  margin: 17px 0;
  .ant-card-head,
  .ant-card-cover,
  .ant-card-body {
    border: 1px solid var(--outline);
  }

  .ant-card-head {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom: none;
  }

  .ant-card-cover {
    border-radius: 8px;
  }

  .ant-card-body {
    border-top: none;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}

@primary-color: #EA4D89;@text-color: #1d2c3b;@text-muted: #8C9DB2;@text-color-secondary: #8C9DB2;@font-size-lg: 14px;@card-head-font-size: 14px;@border-radius-base: 4px;