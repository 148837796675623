.tabs.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 4px;
}

.tabs.ant-tabs-top .ant-tabs-tab {
  font-size: 22px;
}

.tabs.ant-tabs-top .ant-tabs-tab:not(.ant-tabs-tab-active) {
  color: var(--light-color);
}

@primary-color: #EA4D89;@text-color: #1d2c3b;@text-muted: #8C9DB2;@text-color-secondary: #8C9DB2;@font-size-lg: 14px;@card-head-font-size: 14px;@border-radius-base: 4px;