.formContainer {
  width: 350px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.authForm {
  margin-top: 20px;
  width: 350px;
}
.authForm .ant-input {
  height: 44px !important;
  color: #162745;
  font-size: 13px;
  letter-spacing: 0;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  opacity: 1;
}

.authForm .ant-input-affix-wrapper {
  height: 44px !important;
  display: flex;
  flex-direction: row;
  align-items: center;

  .ant-input {
    height: 40px !important;
  }
}

.authForm .ant-btn-block {
  height: 44px !important;
  width: 100% !important;
}

.authForm .anticon {
  font-size: 16px;
  padding-top: 2px;
}

.authForm .ant-checkbox-wrapper {
  color: var(--light-color);
  font-size: 13px;
  letter-spacing: 0;
}

.authForm .ant-form-item-explain {
  font-size: 11px;
  margin: 2px 5px;
}

.authTitle {
  font-weight: bold;
  font-size: 40px;
  letter-spacing: 0px;
  color: var(--main-color);
  text-transform: capitalize;
  opacity: 1;
}

.instruction {
  font-size: 22px;
  letter-spacing: 0px;
  color: var(--light-color);
  opacity: 1;
}

.submitButton {
  color: #fff;
  background-color: var(--justbacker);
  font-size: 13px;
  letter-spacing: 0;
  text-align: center;
  text-transform: capitalize;
  border: 1px solid var(--justbacker);

  border-radius: 4px;
  opacity: 1;
}

.submitButton:hover {
  color: var(--justbacker);
  border: 1px solid var(--justbacker) !important;
  background-color: #fff !important;
}

.helperButton {
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0;
  text-align: center;
  border-radius: 4px;
  opacity: 1;
}

.twitterButton {
  color: #02aff0;
  border: 1px solid #02aff0;
}

.googleButton {
  color: #4385f4;
  border: 1px solid #4385f4;
}

.lineContainer {
  display: flex;
  flex-direction: row;
  width: 350px;
}

.authText {
  color: var(--light-color);
  font-size: 13px;
  letter-spacing: 0;
}

.link {
  margin-left: 5px;
  color: var(--unnamed-color-6a7bff);
  opacity: 1;
  text-decoration: underline;
}

.divider {
  margin: 20px 0;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0px;
  color: var(--light-color);
  opacity: 1;
}

@primary-color: #EA4D89;@text-color: #1d2c3b;@text-muted: #8C9DB2;@text-color-secondary: #8C9DB2;@font-size-lg: 14px;@card-head-font-size: 14px;@border-radius-base: 4px;