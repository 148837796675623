.thankYou {
  position: relative;
  padding-top: 43px;
  height: 100%;
  z-index: 100;
}

.thankYouTriangleContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.thankYouTriangle {
  width: 0;
  height: 0;
  border-bottom: 240px solid #ffe0f9;
  border-right: 1542px solid transparent;
}

@primary-color: #EA4D89;@text-color: #1d2c3b;@text-muted: #8C9DB2;@text-color-secondary: #8C9DB2;@font-size-lg: 14px;@card-head-font-size: 14px;@border-radius-base: 4px;