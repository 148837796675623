.camera {
  color: white;
  background-color: var(--light-color);
  border-radius: 4px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  transition: all 0.2s ease-in-out;
  opacity: 1;
}

.camera.hidden {
  opacity: 0;
}

@primary-color: #EA4D89;@text-color: #1d2c3b;@text-muted: #8C9DB2;@text-color-secondary: #8C9DB2;@font-size-lg: 14px;@card-head-font-size: 14px;@border-radius-base: 4px;