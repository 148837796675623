@text-muted: #8c9db2;
:root {
  /* Colors: */
  --unnamed-color-f3f5f7: #f3f5f7;
  --light-color: #8c9db2;
  --main-color: #1d2c3b;
  --unnamed-color-6a7bff: #6a7bff;
  --outline: #e5e9ec;
  --justbacker: #ea4d89;
  --unnamed-color-b0b0b0: #b0b0b0;

  /* Font/text values */
  --unnamed-font-family-open-sans: Open Sans;
  --unnamed-font-family-poppins: Poppins;
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-bold: bold;
  --unnamed-font-weight-normal: normal;
  --unnamed-font-size-14: 14px;
  --unnamed-font-size-20: 20px;
  --unnamed-font-size-26: 26px;
  --unnamed-font-size-36: 36px;
  --unnamed-font-size-44: 44px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-line-spacing-21: 21px;
  --unnamed-line-spacing-27: 27px;
  --unnamed-line-spacing-36: 36px;
  --unnamed-line-spacing-49: 49px;
  --unnamed-line-spacing-60: 60px;
  --unnamed-text-transform-titlecase: titlecase;
}

/* Character Styles */
.h2-mobile—-20pt {
  font-family: var(--unnamed-font-family-open-sans);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-20);
  line-height: var(--unnamed-line-spacing-27);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--main-color);
  text-transform: var(--unnamed-text-transform-titlecase);
}
.h2-—-26pt {
  font-family: var(--unnamed-font-family-open-sans);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-26);
  line-height: var(--unnamed-line-spacing-36);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--main-color);
  text-transform: var(--unnamed-text-transform-titlecase);
}
.h1-mobile-—-36pt {
  font-family: var(--unnamed-font-family-open-sans);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-36);
  line-height: var(--unnamed-line-spacing-49);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--main-color);
  text-transform: var(--unnamed-text-transform-titlecase);
}
.h1-—-44pt {
  font-family: var(--unnamed-font-family-open-sans);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-44);
  line-height: var(--unnamed-line-spacing-60);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--main-color);
  text-transform: var(--unnamed-text-transform-titlecase);
}
.body-—-14pt {
  font-family: var(--unnamed-font-family-poppins);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-21);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--light-color);
}
