.headerSignUpButton {
  width: 130px;
  color: var(--justbacker);
  background-color: #ffffff;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  text-transform: capitalize;
  border: 1px solid var(--justbacker);
  border-radius: 4px;
  opacity: 1;
}

@primary-color: #EA4D89;@text-color: #1d2c3b;@text-muted: #8C9DB2;@text-color-secondary: #8C9DB2;@font-size-lg: 14px;@card-head-font-size: 14px;@border-radius-base: 4px;