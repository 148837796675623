.FeaturedProfile__button {
  width: 136px;
  height: 40px;
  background: #f3f5f7;
  border-radius: 4px;
  //   color: black;
  border: none !important;
  font: normal normal 600 14px/19px;
  font-weight: bold;
}
.FeaturedProfile__listItem {
  .ant-list-item-meta-content {
    align-self: center;
  }
  .ant-list-item-meta-title > a,
  .profile__info {
    font-weight: bold;
  }
  .profile__info {
    display: flex;
    width: 35%;
    justify-content: space-between;
  }
}

@primary-color: #EA4D89;@text-color: #1d2c3b;@text-muted: #8C9DB2;@text-color-secondary: #8C9DB2;@font-size-lg: 14px;@card-head-font-size: 14px;@border-radius-base: 4px;