.settings__notifications__section {
  color: var(--main-color);
  font-size: 26px;
  text-transform: capitalize;
  font-weight: bold;
}

.settings__notifications__section.topOffset {
  margin-top: 40px;
}

.settings__notifications__form {
  margin-top: 25px;
  margin-bottom: 25px;
}

.settings__notifications__button {
  margin-left: 50%;
  transform: translateX(-50%);
  width: 350px;
  height: 56px;
}

.settings__notifications__button:not(:first-child) {
  margin-top: 20px;
}

.settings__notifications__row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.settings__notifications__row > :not(:last-child) {
  margin-right: 73px;
}

.settings__notifications__checkboxes {
  margin-top: 15px;
}

.settings__notifications__checkboxes > * {
  margin-bottom: 10px;
}

@primary-color: #EA4D89;@text-color: #1d2c3b;@text-muted: #8C9DB2;@text-color-secondary: #8C9DB2;@font-size-lg: 14px;@card-head-font-size: 14px;@border-radius-base: 4px;