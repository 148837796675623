.questionWithRadio {
  font-size: 18px;
  text-align: center;
  text-transform: capitalize;

  :global {
    .ant-radio-inner {
      border-radius: none;
    }
  }
}

@primary-color: #EA4D89;@text-color: #1d2c3b;@text-muted: #8C9DB2;@text-color-secondary: #8C9DB2;@font-size-lg: 14px;@card-head-font-size: 14px;@border-radius-base: 4px;