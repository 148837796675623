.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 72px;
  z-index: 200;
  background-color: #fff;
  border-bottom: 2px solid var(--outline);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.headerChildrenContent {
  width: calc(100vw - 300px);
  height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.header img {
  height: 32px;
}

@primary-color: #EA4D89;@text-color: #1d2c3b;@text-muted: #8C9DB2;@text-color-secondary: #8C9DB2;@font-size-lg: 14px;@card-head-font-size: 14px;@border-radius-base: 4px;