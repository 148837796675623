.feedback {
  display: flex;
  height: calc(100% - 72px);
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 0;
}

.form {
  margin-top: 60px;
  text-align: center;
}

.form button {
  width: 235px;
  height: 56px;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 19px;
}

.feedbackItemLabel {
  font-size: 18px;
  text-transform: capitalize;
}

.feedbackCount {
  font-size: var(--unnamed-font-size-14);
  text-transform: capitalize;
}

@primary-color: #EA4D89;@text-color: #1d2c3b;@text-muted: #8C9DB2;@text-color-secondary: #8C9DB2;@font-size-lg: 14px;@card-head-font-size: 14px;@border-radius-base: 4px;